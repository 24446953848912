// src/components/Funciones.js
import React, { useState } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import './Funciones.css';

const Funciones = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (e) => {
    e.preventDefault(); // Prevenir el desplazamiento al tope de la página
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <Container className="funciones-section py-5">
      <Row>
        <Col md={12} className="text-center mb-4">
          <h2>Nuestras <span className="funciones-highlight">Características</span></h2>
          <p>Funciones avanzadas para un aprendizaje interactivo y eficiente, diseñadas para enriquecer la experiencia educativa en ELEVA.</p>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col md={6} className="funciones-image-section">
          <img src="/images/circlepurple.png" alt="Purple circle" className="funciones-circle-purple" />
          <img src="/images/circlelightblue.png" alt="Light blue circle" className="funciones-circle-lightblue" />
          <img src="/images/groupcall2.png" alt="Videollamada grupal" className="funciones-group-call-image" />
          <img src="/images/circledeepblue.png" alt="Deep blue circle" className="funciones-circle-deepblue" />
          <img src="/images/circlegreen.png" alt="Green circle" className="funciones-circle-green" />
        </Col>
        <Col md={6} className="funciones-text-section">
          <h3 className="funciones-method-title">Un <span className="funciones-highlight">método</span> diseñado para aprender.</h3>
          <div className="funciones-feature">
            <img src="/images/share.png" alt="Compartir pantalla" className="funciones-feature-icon" />
            <p>Comparte pantallas y materiales de forma clara y directa.</p>
          </div>
          <div className="funciones-feature">
            <img src="/images/comunicacion.png" alt="Comunicación directa" className="funciones-feature-icon" />
            <p>Comunicación directa en tiempo real.</p>
          </div>
          <div className="funciones-feature">
            <img src="/images/teachergroup.png" alt="Espacio para instructores" className="funciones-feature-icon" />
            <p>Espacio dedicado para que los instructores destaquen.</p>
          </div>
        </Col>
      </Row>
      <Row className="funciones-new-section mt-5">
        <Col md={6} className="funciones-text-section">
          <h2 className="funciones-new-section-title">
            <span className="funciones-highlight">Herramientas</span> para Educadores y Estudiantes
          </h2>
          <p>
            ELEVA proporciona herramientas educativas dinámicas para uso en clase, permitiendo a los educadores asignar y a los estudiantes entregar tareas en tiempo real.
          </p>
        </Col>
        <Col md={6} className="funciones-image-section">
          <img src="/images/student3.png" alt="Estudiante" className="funciones-student-image" />
        </Col>
      </Row>
      <Row className="funciones-evaluations-section mt-5">
        <Col md={6} className="funciones-evaluations-image-section">
          <img src="/images/eval1.png" alt="Evaluaciones" className="funciones-eval-image" />
        </Col>
        <Col md={6} className="funciones-text-section">
          <h2 className="funciones-evaluations-title">
            <span className="highlight-2">Evaluaciones,</span><br />
            <span className="highlight-1">Cuestionarios</span> y <span className="highlight-2">Pruebas</span>
          </h2>
          <p>
            Lanza fácilmente asignaciones en vivo, cuestionarios y pruebas en ELEVA.
          </p>
        </Col>
      </Row>
      <Row className="funciones-teacher-management-section mt-5">
        <Col md={6} className="funciones-text-section">
          <h2 className="funciones-teacher-management-title">
            <span className="highlight-1">Gestión de Clases</span> <br />
            <span className="highlight-2">para Educadores</span>
          </h2>
          <p>
            ELEVA proporciona herramientas esenciales para administrar la clase, como listas de alumnos, seguimiento de asistencia y más. Con el libro de calificaciones digital, los educadores pueden revisar y calificar exámenes y cuestionarios en tiempo real.
          </p>
        </Col>
        <Col md={6} className="funciones-image-section">
          <img src="/images/librocalif.png" alt="Libro de Calificaciones" className="funciones-teacher-management-image" />
        </Col>
      </Row>
      <Row className="funciones-individual-discussions-section mt-5">
        <Col md={6} className="funciones-image-section">
          <img src="/images/indivcall.png" alt="Discusión Individual" className="funciones-discussions-image" />
        </Col>
        <Col md={6} className="funciones-text-section">
          <h2 className="funciones-discussions-title">
            <span className="highlight-1">Discusiones</span> <br />
            <span className="highlight-2">Individuales</span>
          </h2>
          <p>
            ELEVA permite conversaciones privadas entre educadores y estudiantes dentro del entorno virtual.
          </p>
          {/* Botón con el nuevo color y sin subrayado */}
          <a href="#" className="learn-more" onClick={handleShowModal}>Ver más características</a>
        </Col>
      </Row>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Características de ELEVA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="modal-features-list">
            <li><strong style={{ color: '#6B68F2' }}>Interacción Directa:</strong> Cada estudiante puede mantener conversaciones individuales con sus mentores para recibir orientación específica sobre sus temas de interés o áreas de mejora.</li>
            <li><strong style={{ color: '#6B68F2' }}>Plataforma Segura:</strong> Las discusiones se realizan dentro de un entorno protegido, lo que asegura la privacidad de la comunicación entre mentor y aprendiz.</li>
            <li><strong style={{ color: '#6B68F2' }}>Monitoreo Continuo:</strong> Tanto los mentores como los estudiantes pueden hacer un seguimiento de sus conversaciones previas, lo que ayuda a mantener un registro de los avances y las áreas discutidas.</li>
            <li><strong style={{ color: '#6B68F2' }}>Calendario Interactivo:</strong> Nuestra plataforma cuenta con un calendario interactivo que facilita la programación de sesiones, evitando confusiones y optimizando tu tiempo de aprendizaje.</li>
            <li><strong style={{ color: '#6B68F2' }}>Generación Automática de Enlaces:</strong> Para cada sesión, generamos automáticamente el enlace de Google Meet, asegurando que tanto el mentor como el estudiante tengan acceso inmediato a su reunión virtual.</li>
            <li><strong style={{ color: '#6B68F2' }}>Notificaciones:</strong> Para que no te pierdas ninguna sesión, enviamos notificaciones recordatorias tanto al mentor como al estudiante, garantizando que ambas partes estén al tanto del horario y el enlace de la reunión.</li>
            <li><strong style={{ color: '#6B68F2' }}>Pago Fácil y Seguro:</strong> Contamos con un POS de pago integrado para facilitar las transacciones de manera rápida y segura, haciendo que el proceso de mentoría sea aún más fluido.</li>
            <li><strong style={{ color: '#6B68F2' }}>Otras características:</strong> Registra y organiza tus sesiones fácilmente. Accede a tus conversaciones en cualquier momento y lugar. Facilita la retroalimentación personalizada después de cada interacción.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Funciones;
