// src/components/Features.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Features.css';

const Features = () => (
  <Container className="features-section py-5">
    <Row className="text-center">
      <Col>
        <h2 className="features-title">
          Software <span className="highlight">en la nube.</span>
        </h2>
        <p className="features-description">
          ELEVA es una suite de software en línea que combina herramientas novedosas para llevar a cabo una educación exitosa.
        </p>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col md={3} className="text-center feature-box mx-2">
        <div className="feature-icon-wrapper">
          <img src="/images/admifile.png" alt="Administración de cursos y contratos" className="feature-icon"/>
        </div>
        <div className="feature-content">
          <h3>Administración de Cursos y Contratos</h3>
          <p>Inscribe y gestiona cursos digitales con facilidad, incluyendo contratos y acuerdos educativos.</p>
        </div>
      </Col>
      <Col md={3} className="text-center feature-box mx-2">
        <div className="feature-icon-wrapper">
          <img src="/images/calendar.png" alt="Programación de clases y seguimiento de asistencia" className="feature-icon"/>
        </div>
        <div className="feature-content">
          <h3>Programación de Clases y Seguimiento de Asistencia</h3>
          <p>Organiza y reserva sesiones en línea, con seguimiento completo de la asistencia.</p>
        </div>
      </Col>
      <Col md={3} className="text-center feature-box mx-2">
        <div className="feature-icon-wrapper">
          <img src="/images/progress.png" alt="Seguimiento de Progreso" className="feature-icon"/>
        </div>
        <div className="feature-content">
          <h3>Seguimiento de Progreso</h3>
          <p>Evalúa y supervisa el avance estudiantil, ofreciendo retroalimentación personalizada.</p>
        </div>
      </Col>
    </Row>
  </Container>
);

export default Features;
