// src/components/News.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './News.css';
import axios from 'axios';

const News = () => {
  const [news, setNews] = useState(null);
  const [showFullContent, setShowFullContent] = useState(false); // Para noticia principal
  const [showBubble, setShowBubble] = useState({}); // Para noticias secundarias

  // Función para obtener las noticias desde el backend
  const fetchNews = async () => {
    try {
      const response = await axios.get('http://localhost:3306/api/news');
      const newsData = response.data[response.data.length - 1]; // Obtener la noticia más reciente

      // Convertir las imágenes de bytes a Base64
      const processedNews = {
        ...newsData,
        mainImage: `data:image/jpeg;base64,${arrayBufferToBase64(newsData.mainImage.data)}`,
        sideImage1: `data:image/jpeg;base64,${arrayBufferToBase64(newsData.sideImage1.data)}`,
        sideImage2: `data:image/jpeg;base64,${arrayBufferToBase64(newsData.sideImage2.data)}`,
        sideImage3: `data:image/jpeg;base64,${arrayBufferToBase64(newsData.sideImage3.data)}`,
      };

      setNews(processedNews);
    } catch (error) {
      console.error('Error al obtener las noticias:', error);
    }
  };

  // Función para convertir ArrayBuffer a Base64
  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for(let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    fetchNews(); // Cargar noticias al montar el componente
  }, []);

  // Función para truncar el contenido si supera cierta longitud
  const truncateContent = (content, length, showFull) => {
    if (content.length > length && !showFull) {
      return content.substring(0, length) + '...';
    }
    return content;
  };

  // Manejar clic en "Leer más" para la noticia principal
  const toggleFullContent = (e) => {
    e.preventDefault();
    setShowFullContent(!showFullContent);
  };

  // Manejar clic en "Leer más" para noticias secundarias
  const toggleBubble = (index) => {
    setShowBubble((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <Container className="news-section py-5">
      <Row>
        <Col md={12} className="text-center mb-4">
          <h2>Últimas <span className="news-highlight">Noticias y Recursos</span></h2>
          <p>Descubre las novedades y desarrollos más recientes en ELEVA Bolivia</p>
        </Col>
      </Row>
      {news ? (
        <Row className="justify-content-center align-items-start">
          {/* Noticia Principal */}
          <Col md={8} className="news-main">
            <img src={news.mainImage} alt="Principal" className="news-main-image" />
            <div className="news-main-content">
              {/* Estilo personalizado pero coherente con las secundarias */}
              <span className="news-main-label">{news.category || "Novedades"}</span>
              <h3>{news.title}</h3>

              {/* Párrafo con truncado y expansión */}
              <p>
                {truncateContent(news.content, 150, showFullContent)}
              </p>

              {news.content.length > 150 && (
                <a href="#!" className="news-read-more" onClick={toggleFullContent}>
                  {showFullContent ? 'Leer menos' : 'Leer más'}
                </a>
              )}
            </div>
          </Col>

          {/* Noticias Secundarias */}
          <Col md={4} className="news-side">
            {[1, 2, 3].map((i) => (
              <div key={i} className="news-side-item mb-4">
                <div className="news-side-image-container">
                  <img src={news[`sideImage${i}`]} alt={`Secundaria ${i}`} className="news-side-image" />
                  <span className="news-side-label">{news[`sideCategory${i}`]}</span>
                </div>
                <div className="news-side-content">
                  <h4>{news[`sideTitle${i}`]}</h4>
                  <p>{truncateContent(news[`sideContent${i}`], 110)}</p>
                  {news[`sideContent${i}`].length > 110 && (
                    <a href="#!" className="news-read-more" onClick={(e) => { e.preventDefault(); toggleBubble(i); }}>
                      Leer más
                    </a>
                  )}

                  {/* Burbuja flotante para mostrar contenido completo */}
                  {showBubble[i] && (
                    <div className="bubble-content">
                      <p>{news[`sideContent${i}`]}</p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </Col>
        </Row>
      ) : (
        <div className="no-news-message">
          <h4>¡Próximamente!</h4>
        </div>
      )}
    </Container>
  );
};

export default News;
