// src/AppRouter.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import Login from "./components/Login"; // Importar el componente Login
import AdminPanel from "./components/AdminPanel"; // Importar AdminPanel
import TermsAndConditions from "./components/TermsAndConditions";
import RegistroExitoso from "./components/RegistroExitoso"; // Importar RegistroExitoso

const AppRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/login" element={<Login />} /> {/* Ruta para la página de inicio de sesión */}
      <Route path="/admin" element={<AdminPanel />} /> {/* Ruta para AdminPanel */}
      <Route path="/terminos-condiciones" element={<TermsAndConditions />} />
      <Route path="/registro-exitoso" element={<RegistroExitoso />} /> {/* Ruta para el registro exitoso */}
    </Routes>
  </Router>
);

export default AppRouter;
