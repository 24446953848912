// src/components/AdminPanel.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminPanel.css';
import './News.css'; // Importamos el CSS de News para reutilizar los estilos de vista previa

const AdminPanel = () => {
  const [form, setForm] = useState({
    mainTitle: '',
    mainContent: '',
    mainCategory: '',
    mainImage: null,
    sideTitle1: '',
    sideContent1: '',
    sideCategory1: '',
    sideImage1: null,
    sideTitle2: '',
    sideContent2: '',
    sideCategory2: '',
    sideImage2: null,
    sideTitle3: '',
    sideContent3: '',
    sideCategory3: '',
    sideImage3: null,
  });

  const [previewImages, setPreviewImages] = useState({
    mainImage: null,
    sideImage1: null,
    sideImage2: null,
    sideImage3: null,
  });

  // Función para convertir ArrayBuffer a Base64
  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for(let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  // Obtener noticias almacenadas
  const fetchNews = async () => {
    try {
      const response = await axios.get('http://localhost:3306/api/news');
      const latestNews = response.data[response.data.length - 1]; // Obtener la última noticia

      // Convertir imágenes a Base64 para mostrarlas en la vista previa
      const processedNews = {
        ...latestNews,
        mainImage: latestNews.mainImage ? `data:image/jpeg;base64,${arrayBufferToBase64(latestNews.mainImage.data)}` : null,
        sideImage1: latestNews.sideImage1 ? `data:image/jpeg;base64,${arrayBufferToBase64(latestNews.sideImage1.data)}` : null,
        sideImage2: latestNews.sideImage2 ? `data:image/jpeg;base64,${arrayBufferToBase64(latestNews.sideImage2.data)}` : null,
        sideImage3: latestNews.sideImage3 ? `data:image/jpeg;base64,${arrayBufferToBase64(latestNews.sideImage3.data)}` : null,
      };

      // Establecer los campos de texto en el estado del formulario
      setForm({
        mainTitle: latestNews.mainTitle || latestNews.title || '',
        mainContent: latestNews.mainContent || latestNews.content || '',
        mainCategory: latestNews.mainCategory || latestNews.category || '',
        mainImage: null, // No establecemos la imagen en el estado del formulario
        sideTitle1: latestNews.sideTitle1 || '',
        sideContent1: latestNews.sideContent1 || '',
        sideCategory1: latestNews.sideCategory1 || '',
        sideImage1: null,
        sideTitle2: latestNews.sideTitle2 || '',
        sideContent2: latestNews.sideContent2 || '',
        sideCategory2: latestNews.sideCategory2 || '',
        sideImage2: null,
        sideTitle3: latestNews.sideTitle3 || '',
        sideContent3: latestNews.sideContent3 || '',
        sideCategory3: latestNews.sideCategory3 || '',
        sideImage3: null,
      });

      // Establecer las imágenes de vista previa
      setPreviewImages({
        mainImage: processedNews.mainImage,
        sideImage1: processedNews.sideImage1,
        sideImage2: processedNews.sideImage2,
        sideImage3: processedNews.sideImage3,
      });

    } catch (error) {
      console.error('Error al obtener las noticias', error);
    }
  };

  useEffect(() => {
    fetchNews(); // Cargar noticias desde el servidor al cargar el componente
  }, []);

  // Manejar cambios en los inputs de texto
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  // Manejar la carga de archivos y generar vista previa
  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (file) {
      setForm({ ...form, [name]: file });

      // Generar vista previa de la imagen
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImages((prev) => ({ ...prev, [name]: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Enviar formulario para crear o actualizar noticias
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();

      // Append all fields to FormData
      for (const key in form) {
        if (form.hasOwnProperty(key)) {
          // Solo agregamos el campo al FormData si tiene valor
          if (form[key]) {
            formData.append(key, form[key]);
          }
        }
      }

      await axios.post('http://localhost:3306/api/news', formData);
      alert('Noticias actualizadas correctamente');
      fetchNews(); // Recargar noticias actualizadas
    } catch (error) {
      console.error('Error al crear la noticia', error);
      alert('Error al crear la noticia');
    }
  };

  // Función para truncar el contenido si supera cierta longitud
  const truncateContent = (content, length) => {
    if (content && content.length > length) {
      return content.substring(0, length) + '...';
    }
    return content;
  };

  return (
    <div className="admin-panel">
      <h2>Panel Administrativo de Noticias</h2>
      <form onSubmit={handleSubmit}>
        <h3>Noticia Principal</h3>
        <div>
          <label htmlFor="mainTitle">Título Principal:</label>
          <input
            type="text"
            id="mainTitle"
            name="mainTitle"
            value={form.mainTitle}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="mainContent">Contenido Principal:</label>
          <textarea
            id="mainContent"
            name="mainContent"
            value={form.mainContent}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="mainCategory">Categoría Principal:</label>
          <input
            type="text"
            id="mainCategory"
            name="mainCategory"
            value={form.mainCategory}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="mainImage">Imagen Principal:</label>
          <input
            type="file"
            id="mainImage"
            name="mainImage"
            onChange={handleFileChange}
          />
        </div>

        <h3>Noticias Secundarias</h3>

        {/* Noticia Secundaria 1 */}
        <div>
          <label htmlFor="sideTitle1">Título Secundario 1:</label>
          <input
            type="text"
            id="sideTitle1"
            name="sideTitle1"
            value={form.sideTitle1}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="sideContent1">Contenido Secundario 1:</label>
          <textarea
            id="sideContent1"
            name="sideContent1"
            value={form.sideContent1}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="sideCategory1">Categoría Secundaria 1:</label>
          <input
            type="text"
            id="sideCategory1"
            name="sideCategory1"
            value={form.sideCategory1}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="sideImage1">Imagen Secundaria 1:</label>
          <input
            type="file"
            id="sideImage1"
            name="sideImage1"
            onChange={handleFileChange}
          />
        </div>

        {/* Noticia Secundaria 2 */}
        <div>
          <label htmlFor="sideTitle2">Título Secundario 2:</label>
          <input
            type="text"
            id="sideTitle2"
            name="sideTitle2"
            value={form.sideTitle2}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="sideContent2">Contenido Secundario 2:</label>
          <textarea
            id="sideContent2"
            name="sideContent2"
            value={form.sideContent2}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="sideCategory2">Categoría Secundaria 2:</label>
          <input
            type="text"
            id="sideCategory2"
            name="sideCategory2"
            value={form.sideCategory2}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="sideImage2">Imagen Secundaria 2:</label>
          <input
            type="file"
            id="sideImage2"
            name="sideImage2"
            onChange={handleFileChange}
          />
        </div>

        {/* Noticia Secundaria 3 */}
        <div>
          <label htmlFor="sideTitle3">Título Secundario 3:</label>
          <input
            type="text"
            id="sideTitle3"
            name="sideTitle3"
            value={form.sideTitle3}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="sideContent3">Contenido Secundario 3:</label>
          <textarea
            id="sideContent3"
            name="sideContent3"
            value={form.sideContent3}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="sideCategory3">Categoría Secundaria 3:</label>
          <input
            type="text"
            id="sideCategory3"
            name="sideCategory3"
            value={form.sideCategory3}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="sideImage3">Imagen Secundaria 3:</label>
          <input
            type="file"
            id="sideImage3"
            name="sideImage3"
            onChange={handleFileChange}
          />
        </div>

        <button type="submit">Actualizar Noticias</button>
      </form>

      {/* Vista Previa de la Noticia */}
      <h2>Vista Previa</h2>
      <div className="news-section">
        {/* Noticia Principal */}
        <div className="news-main">
          {previewImages.mainImage ? (
            <img src={previewImages.mainImage} alt="Principal" className="news-main-image" />
          ) : (
            <img src="#" alt="Principal" className="news-main-image" />
          )}
          <div className="news-main-content">
            <span className="news-main-label">{form.mainCategory || 'Novedades'}</span>
            <h3>{form.mainTitle}</h3>
            <p>{truncateContent(form.mainContent, 150)}</p>
          </div>
        </div>

        {/* Noticias Secundarias */}
        <div className="news-side">
          {[1, 2, 3].map((i) => (
            <div key={i} className="news-side-item mb-4">
              <div className="news-side-image-container">
                {previewImages[`sideImage${i}`] ? (
                  <img
                    src={previewImages[`sideImage${i}`]}
                    alt={`Secundaria ${i}`}
                    className="news-side-image"
                  />
                ) : (
                  <img src="#" alt={`Secundaria ${i}`} className="news-side-image" />
                )}
                <span className="news-side-label">{form[`sideCategory${i}`]}</span>
              </div>
              <div className="news-side-content">
                <h4>{form[`sideTitle${i}`]}</h4>
                <p>{truncateContent(form[`sideContent${i}`], 110)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
