// src/components/RegistroExitoso.js

import React from 'react';

const RegistroExitoso = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <h1>¡Registro Exitoso!</h1>
      <p>¡Gracias por registrarte! Pronto recibirás más noticias.</p>
    </div>
  );
};

export default RegistroExitoso;
