// src/components/Hero.js
import React, { useState, useRef } from 'react';
import { Container, Row, Col, Button, Modal, Overlay } from 'react-bootstrap';
import './Hero.css';

const Hero = () => {
  // Estado para controlar la visibilidad del modal de video
  const [showVideo, setShowVideo] = useState(false);

  // Estado para controlar la visibilidad de la burbuja "¡Próximamente!"
  const [showBubble, setShowBubble] = useState(false);

  // Referencia al botón "Emprende tu futuro"
  const futureButtonRef = useRef(null);

  // Funciones para abrir y cerrar el modal
  const handleVideoOpen = () => setShowVideo(true);
  const handleVideoClose = () => setShowVideo(false);

  // Manejar clic en "Emprende tu futuro"
  const handleFutureClick = (e) => {
    e.preventDefault();
    setShowBubble(!showBubble);
  };

  return (
    <>
      <Container fluid className="hero-section text-center py-5">
        <Row className="align-items-center">
          <Col md={6} className="text-md-left">
            <h1 className="hero-title">
              <span className="hero-main-title">Estudiar</span>{' '}
              <span className="hero-main-title-secondary">en línea ahora</span><br/>
              <span className="hero-sub-title">es mucho más fácil.</span>
            </h1>
            <p>ELEVA es una plataforma innovadora que te enseñará de manera más interactiva.</p>
            <div className="button-container">
              <Button
                variant="primary"
                className="mr-2 custom-button"
                ref={futureButtonRef}
                onClick={handleFutureClick}
              >
                Emprende tu futuro
              </Button>
              <Button className="custom-button play-button" onClick={handleVideoOpen}>
                <img src="/images/playbutton.png" alt="Play" className="play-icon" />
              </Button>
              <span className="play-text">Mira cómo funciona</span>
            </div>
          </Col>
          <Col md={6} className="d-none d-md-block">
            <div className="hero-image-container"> 
              <img src="/images/headerback1.png" alt="Student" className="hero-image" />
            </div>
          </Col>
        </Row>
      </Container>

      {/* Burbuja para "Emprende tu futuro" */}
      <Overlay
        target={futureButtonRef.current}
        show={showBubble}
        placement="bottom"
        containerPadding={20}
        rootClose={true}
        onHide={() => setShowBubble(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            className="bubble"
            {...props}
            style={{
              ...props.style,
            }}
          >
            ¡Próximamente!
          </div>
        )}
      </Overlay>

      {/* Modal para el video */}
      <Modal
        show={showVideo}
        onHide={handleVideoClose}
        centered
        size="lg"
        contentClassName="video-modal"
        dialogClassName="video-modal-dialog"
      >
        <Modal.Body className="p-0">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/gM0LYuMWnmA?autoplay=1&rel=0&showinfo=0&controls=0"
              title="ELEVA | Tu conexión directa con mentores para transformar tu futuro"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Hero;
