// src/components/Partners.js
import React from 'react';
import './Partners.css';

const Partners = () => (
  <div className="partners-section">
    <div className="partners-title">Conoce a nuestros partners</div>
    <div className="logo-container">
      <img src="/images/recoleta.png" alt="Recoleta Espacio Sustentable" className="logo logo-recoleta" />
      <img src="/images/leanin.png" alt="Lean In Network Bolivia" className="logo logo-leanin" />
      <img src="/images/ruth.png" alt="Ruth Rimmer Coaching" className="logo logo-ruth" />
    </div>
  </div>
);

export default Partners;
