import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import './Register.css';

const Register = ({ onClose, onRegisterSuccess }) => {
  const [full_name, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const res = await fetch('http://localhost:3306/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ full_name, email, phone }),
      });

      const contentType = res.headers.get('content-type');
      let data;

      if (contentType && contentType.includes('application/json')) {
        data = await res.json();
      } else {
        throw new Error('Respuesta no es JSON');
      }

      if (!res.ok) {
        setError(data.message || 'Error desconocido al registrar el usuario');
        return;
      }

      // Si el registro es exitoso, muestra el modal de éxito
      onRegisterSuccess();

      // Cierra el formulario de registro después de un tiempo si es necesario
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error en la solicitud:', error);
      setError('Error registrando el usuario');
    }
  };

  const handleGoogleSignIn = () => {
    window.location.href = 'http://localhost:3306/auth/google';
  };

  return (
    <div>
      <div className="register-modal-overlay" onClick={onClose}></div>
      <div className="register-modal">
        <button className="register-modal-close" onClick={onClose}>×</button>
        <h2>Registrarse</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Nombre Completo"
            value={full_name}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Correo Electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Número de Teléfono"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button type="submit">Registrarse</button>
        </form>
        <div className="divider">o</div>
        <button className="google-button" onClick={handleGoogleSignIn}>
          <FontAwesomeIcon icon={faGoogle} /> Registrarse con Google
        </button>
      </div>
    </div>
  );
};

export default Register;
