// src/components/Header.js
import React, { useState, useRef, useEffect } from 'react';
import { Navbar, Nav, Button, Overlay } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AboutUs from './AboutUs'; // Importar AboutUs
import './Header.css';

const Header = ({ onRegisterClick }) => {
  // Estados para controlar la visibilidad de las burbujas y el modal
  const [showCursosBubble, setShowCursosBubble] = useState(false);
  const [showBlogBubble, setShowBlogBubble] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);

  // Referencias a los elementos de los botones
  const cursosRef = useRef(null);
  const blogRef = useRef(null);

  // Manejar clic en "Cursos"
  const handleCursosClick = (e) => {
    e.preventDefault();
    setShowCursosBubble(!showCursosBubble);
    setShowBlogBubble(false); // Cerrar la burbuja de Blog si está abierta
  };

  // Manejar clic en "Blog"
  const handleBlogClick = (e) => {
    e.preventDefault();
    setShowBlogBubble(!showBlogBubble);
    setShowCursosBubble(false); // Cerrar la burbuja de Cursos si está abierta
  };

  // Manejar clic en "Sobre Nosotros"
  const handleAboutClick = (e) => {
    e.preventDefault();
    setShowAboutUs(true);
  };

  // Cerrar el modal al presionar la tecla Esc
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setShowAboutUs(false);
      }
    };

    if (showAboutUs) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showAboutUs]);

  // Prevenir el desplazamiento de la página de fondo cuando el modal está abierto
  useEffect(() => {
    if (showAboutUs) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showAboutUs]);

  return (
    <>
      <Navbar expand="lg" className="header">
        <Navbar.Brand href="#home" className="ml-3">
          <img src="/images/logo_eleva.png" alt="ELEVA Bolivia" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto mr-3">
            <Nav.Link href="#home">Inicio</Nav.Link>
            <Nav.Link href="#courses" ref={cursosRef} onClick={handleCursosClick}>
              Cursos
            </Nav.Link>
            <Nav.Link href="#blog" ref={blogRef} onClick={handleBlogClick}>
              Blog
            </Nav.Link>
            <Nav.Link href="#about" onClick={handleAboutClick}>
              Sobre Nosotros
            </Nav.Link>
            <Button variant="primary" className="register-button" onClick={onRegisterClick}>
              Registrarse
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Burbuja para "Cursos" */}
      <Overlay
        target={cursosRef.current}
        show={showCursosBubble}
        placement="bottom"
        containerPadding={20}
        rootClose={true}
        onHide={() => setShowCursosBubble(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            className="bubble"
            {...props}
            style={{
              ...props.style,
            }}
          >
            ¡Próximamente!
          </div>
        )}
      </Overlay>

      {/* Burbuja para "Blog" */}
      <Overlay
        target={blogRef.current}
        show={showBlogBubble}
        placement="bottom"
        containerPadding={20}
        rootClose={true}
        onHide={() => setShowBlogBubble(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            className="bubble"
            {...props}
            style={{
              ...props.style,
            }}
          >
            ¡Próximamente!
          </div>
        )}
      </Overlay>

      {/* Modal para About Us */}
      {showAboutUs && (
        <div
          className="about-modal"
          onClick={() => setShowAboutUs(false)}
        >
          <div
            className="about-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="close-button" onClick={() => setShowAboutUs(false)}>
              <img src="/images/xmark.svg" alt="Cerrar" className="close-icon" />
            </button>
            <AboutUs />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
