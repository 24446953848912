// src/App.js
import React, { useState } from 'react';
import Header from './components/Header';
import Register from './components/Register';
import Hero from './components/Hero';
import Partners from './components/Partners';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import News from './components/News';
import Footer from './components/Footer';
import AboutEleva from './components/AboutEleva';
import Funciones from './components/Funciones';
import CookieBanner from './components/CookieBanner';
import CookiePolicyPage from './components/CookiePolicyPage';
import AboutUs from './components/AboutUs';
import { SessionProvider } from "next-auth/react";
import WelcomeBanner from './components/WelcomeBanner';
import { Modal, Button } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  const [showCookies, setShowCookies] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false); // Estado para controlar el modal de éxito

  const handleCookiePolicyClick = () => {
    setShowCookies(true);
  };

  const handleCloseCookiePolicy = () => {
    setShowCookies(false);
  };

  // Maneja la apertura del formulario de registro
  const handleRegisterClick = () => {
    setShowRegister(true);
  };

  // Maneja el cierre del formulario de registro
  const handleCloseRegister = () => {
    setShowRegister(false);
  };

  // Mostrar el modal de éxito al completar el registro con éxito
  const handleSuccessModalShow = () => {
    setShowSuccessModal(true);
  };

  // Cerrar el modal de éxito
  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
  };

  return (
    <SessionProvider>
      <div>
        <WelcomeBanner />
        <Header onRegisterClick={handleRegisterClick} />
        <Hero />
        <Features />
        <AboutEleva onRegisterClick={handleRegisterClick} />
        <Funciones />
        <News />
        <Partners />
        <Footer onCookiePolicyClick={handleCookiePolicyClick} />
        <CookieBanner onCookiePolicyClick={handleCookiePolicyClick} />

        {showCookies && (
          <div className="cookies-modal">
            <div className="cookies-modal-content">
              <button className="close-button" onClick={handleCloseCookiePolicy}>Cerrar</button>
              <CookiePolicyPage />
            </div>
          </div>
        )}

        {showRegister && <Register onClose={handleCloseRegister} onRegisterSuccess={handleSuccessModalShow} />} {/* Pasa la función de éxito */}

        {/* Modal de éxito */}
        <Modal show={showSuccessModal} onHide={handleSuccessModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>¡Registro Exitoso!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¡Gracias por registrarte a Eleva!. Pronto recibirás más noticas.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSuccessModalClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </SessionProvider>
  );
}

export default App;
