import React, { useState } from "react";
import { Link } from "react-router-dom";  
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiePolicyPage from "./CookiePolicyPage";
import AboutUs from "./AboutUs";  // Importar AboutUs
import "./Footer.css";

const Footer = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookies, setShowCookies] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false); // Estado para el modal de About Us

  const handleClick = (event, setShow) => {
    event.preventDefault();  
    setShow(true);
  };

  return (
    <footer className="footer-section">
      <div className="footer-content">
        <div className="footer-logo-section">
          <img src="/images/logo_eleva.png" alt="ELEVA Bolivia" className="footer-logo" />
          <span className="logo-divider"></span>
          <span className="footer-logo-text">
            Clases Virtuales<br />con ELEVA
          </span>
        </div>
        <div className="footer-subscription">
          <h2 className="footer-subscription-title">Suscríbete para recibir nuestro Boletín Informativo</h2>
          <div className="footer-subscription-form">
            <input type="email" placeholder="Tu correo aquí" className="footer-subscription-input" />
            <button className="footer-subscription-button">Suscríbete</button>
          </div>
        </div>
        <div className="footer-links">
          <a href="#" className="footer-link" onClick={(e) => handleClick(e, setShowAboutUs)}>
            About Us
          </a>
          <span className="link-divider"></span>
          <a href="#" className="footer-link" onClick={(e) => handleClick(e, setShowPrivacy)}>
            Política de Privacidad
          </a>
          <span className="link-divider"></span>
          <a href="#" className="footer-link" onClick={(e) => handleClick(e, setShowTerms)}>
            Términos y Condiciones
          </a>
          <span className="link-divider"></span>
          <a href="#" className="footer-link" onClick={(e) => handleClick(e, setShowCookies)}>
            Política de Cookies
          </a>
        </div>
        <div className="footer-copyright">
          © 2024 ELEVA Bolivia.
        </div>

        <div className="admin-link">
          <Link to="/login" className="admin-button">Admin</Link>
        </div>
      </div>

      {/* Modal para About Us */}
      {showAboutUs && (
        <div className="about-modal">
          <div className="about-modal-content">
            <button className="close-button" onClick={() => setShowAboutUs(false)}>
              <img src="/images/xmark.svg" alt="Cerrar" className="close-icon" />
            </button>
            <AboutUs /> {/* Contenido de About Us */}
          </div>
        </div>
      )}

      {/* Modales para políticas */}
      {showTerms && (
        <div className="terms-modal">
          <div className="terms-modal-content">
            <button className="close-button" onClick={() => setShowTerms(false)}>
              <img src="/images/xmark.svg" alt="Cerrar" className="close-icon" />
            </button>
            <TermsAndConditions />
          </div>
        </div>
      )}
      {showPrivacy && (
        <div className="privacy-modal">
          <div className="privacy-modal-content">
            <button className="close-button" onClick={() => setShowPrivacy(false)}>
              <img src="/images/xmark.svg" alt="Cerrar" className="close-icon" />
            </button>
            <PrivacyPolicy />
          </div>
        </div>
      )}
      {showCookies && (
        <div className="cookies-modal">
          <div className="cookies-modal-content">
            <button className="close-button" onClick={() => setShowCookies(false)}>
              <img src="/images/xmark.svg" alt="Cerrar" className="close-icon" />
            </button>
            <CookiePolicyPage />
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
