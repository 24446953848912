// src/components/AboutUs.js
import React from 'react';
import './AboutUs.css';

const AboutUs = () => (
  <div className="about-us-section">
    <div className="about-us-content">
      <h1>¿Quiénes Somos?</h1>
      <p>
        <strong>Eleva Bolivia</strong> es una plataforma de aprendizaje y acción. Nos enfocamos en liderar y guiar a la juventud
        mediante la creación de contenido de alto valor para el desarrollo personal y profesional en su camino hacia el éxito.
        Proporcionamos contenido educativo e informativo y, a través de conexiones con mentores y expertos, ofrecemos
        conocimientos esenciales para el crecimiento y el desarrollo continuo.
      </p>

      <h2>Nuestro Propósito</h2>
      <p>
        Nuestro propósito es crear una experiencia educativa única que permita a los jóvenes bolivianos desarrollar su carrera profesional.
        A través de una plataforma digital, buscamos conectar e intercambiar conocimientos entre aprendices y mentores, fortaleciendo el desarrollo de la juventud y promoviendo proyectos que construyan un futuro prometedor.
      </p>

      <h2>Objetivos</h2>
      <ul>
        <li>Crear y gestionar un espacio de capacitación que promueva el desarrollo de habilidades y brinde apoyo integral a los jóvenes bolivianos.</li>
        <li>Fomentar redes de colaboración para facilitar el éxito de sus proyectos.</li>
      </ul>

      <h2>Nuestro Concepto de Negocio</h2>
      <p>
        Nos enfocamos en tres áreas clave: redes sociales, educación en línea y podcasts. Nuestro modelo incluye el desarrollo de cursos en línea, talleres presenciales, y formación de jóvenes embajadores.
      </p>

      <h2>Desarrollo de Competencias</h2>
      <p>
        Nos centramos en el desarrollo de habilidades esenciales para los jóvenes mediante la implementación de capacitaciones y programas educativos. Además, ofrecemos experiencias colaborativas con mentores expertos.
      </p>

      <h2>Equipo</h2>
      <ul className="about-us-list">
        <li><strong>Nicole Cossio</strong> – Directora Ejecutiva</li>
        <li><strong>Nicole Torres</strong> – Directora de Experiencia del Cliente</li>
        <li><strong>Valeria Padilla</strong> – Directora de Marketing & Publicidad</li>
        <li><strong>Andrés García</strong> – Director de Tecnología</li>
      </ul>



      <h2>Contacto</h2>
      <p>
        Teléfono: +591 75514502 <br />
        Correo: eleva.bol@gmail.com <br />
        Instagram: <a href="https://www.instagram.com/elevabolivia/">@elevabolivia</a><br />
        Facebook: <a href="https://www.facebook.com/profile/">facebook.com/elevabolivia</a>
      </p>
    </div>
  </div>
);

export default AboutUs;
